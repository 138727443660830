
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import { AccountService } from "@/services/login/accountService.ts";
import { useRouter } from "vue-router";
import regExps from "@/utils/regExps";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "UpdatePwd",
  components: {
    Header,
  },
  data() {
    const codeTimmer: any = null;
    return {
      resisterCode: "获取验证码",
      codeTimmerValue: 0, //是否在获取验证码，0是可以获取验证码，其他是正在获取
      btnCodeClikck: true, //是否可以获取验证码

      codeTimmer: codeTimmer,
      maxTimmerValue: 120,
      loginId: "",
      code: "", //短信验证码
      password: "",
      againPassword: "",
      api: new AccountService(),
      router: useRouter(),
      loginClock: true,
    };
  },
  created() {
    this.loginId = SystemUtils.loginUser.phone as string;
  },
  methods: {
    checkCode() {
      if (this.loginClock) {
        this.loginClock = false;
        if (this.loginId == null || this.loginId == "") {
          Utils.presentToastWarning("请输入手机号");
          return;
        }
        //短信验证码修改
        if (this.code == null || this.code == "") {
          Utils.presentToastWarning("请输入验证码");
          return;
        }
        if (!this.password) {
          Utils.presentToastWarning("请输入密码");
          return;
        }
        if (!this.againPassword) {
          Utils.presentToastWarning("请确认密码");
          return;
        }
        if (this.password != this.againPassword) {
          Utils.presentToastWarning("确认密码与新密码不一致");
          return;
        }
        this.validateSMSCode(() => {
          //修改密码
          this.updatePassWord();
        });
      }
      this.loginClock = true;
    },
    //短信验证码
    updatePassWord() {
      this.api
        .updatePassWord({
          loginId: this.loginId,
          password: this.password,
          userId: SystemUtils.loginUser.id,
        })
        .then((success: any) => {
          console.log(success);
          if (!success) {
            Utils.presentToastWarning("修改失败");
          } else {
            this.afterLogin();
          }
        })
        .catch((error: any) => {
          Utils.presentToastWarning("修改失败");
          console.log(JSON.stringify(error));
        });
    },
    afterLogin() {
      Utils.presentToastSuccess("修改成功");
      this.router.go(-1);
    },

    validateSMSCode(callback?: any): void {
      if (this.code != null && this.code.length != 6) {
        return;
      }
      this.api.validateSMSCodeIsCorrect(this.loginId, this.code).then(
        (result: any) => {
          if (result.data) {
            this.stopTimmer();
            if (callback) {
              callback();
            }
          } else {
            Utils.presentToastWarning("验证码无效！");
          }
        },
        () => {
          Utils.presentToastWarning("验证码无效！");
        }
      );
    },

    startTimmer(): void {
      if (this.codeTimmer == null) {
        this.codeTimmerValue = this.maxTimmerValue;
        this.codeTimmer = setInterval(() => {
          if (this.codeTimmerValue > 0) {
            this.codeTimmerValue -= 1;
            this.resisterCode = this.codeTimmerValue + "s";
          } else {
            this.stopTimmer();
            // this.code = "";
          }
        }, 1000);
      }
    },

    stopTimmer(): void {
      if (this.codeTimmer != null) {
        clearInterval(this.codeTimmer);
        this.codeTimmerValue = 0;
        this.resisterCode = "重新获取验证码";
        this.codeTimmer = null;
      }
    },

    //获取验证码
    getCode() {
      if (!this.btnCodeClikck) {
        return;
      }
      this.btnCodeClikck = false;
      if (this.codeTimmerValue == 1) {
        this.btnCodeClikck = true;
        Utils.presentToast("验证码已发送！");
        return;
      }
      if (this.loginId == null || this.loginId == "") {
        Utils.presentToast("请输入手机号码（账号）！");
        this.btnCodeClikck = true;
        return;
      }
      if (!regExps.phoneReg.test(this.loginId)) {
        Utils.presentToastWarning("手机号码格式不正确！");
        this.btnCodeClikck = true;
        return;
      }

      this.codeTimmerValue = -1;
      this.api.getMsgCode(this.loginId).then(
        (result: any) => {
          this.btnCodeClikck = true;
          if (result) {
            //读秒
            this.startTimmer();
          } else {
            this.codeTimmerValue = 0;
          }
        },
        () => {
          this.btnCodeClikck = true;
          this.codeTimmerValue = 0;
        }
      );
    },
  },
});
